import React from 'react';
import { Link } from "react-router-dom";
import { AuthenticationService } from '../../authenticationService';
import { saveAs } from 'file-saver';

import AuthHeader from '../../AuthHeader';
import { HandleResponse } from '../../handleResponse';
import { Config } from '../../config';

import { useParams } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import ChangePasswordForm from './ChangePasswordForm';


class UserAreaView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currentUser: AuthenticationService.currentUserValue,
            productInfos: [],
            products: [],
            loading: true
        };

        this.downloadSerialEvent = this.downloadSerialEvent.bind(this);
        this.transferLicenseEvent = this.transferLicenseEvent.bind(this);

        this.fetchProducts();
    }

    fetchProducts() {
        fetch(Config.apiUrl + '/product',
            {
                method: 'GET',
                headers: AuthHeader()
            })
            .then(HandleResponse)
            .then(data => {
                this.setState({ products: data });
            })
            .catch(this.redirectToLogin);
    }

    fetchProductInfos() {
        fetch(Config.apiUrl + '/userinfo/products',
            {
                method: 'GET',
                headers: AuthHeader()
            })
            .then(HandleResponse)
            .then(data => {
                this.setState({ productInfos: data, loading: false });
            })
            .catch(this.redirectToLogin);
    }

    redirectToLogin = () => {
        this.props.navigate("/login");
    }

    componentDidMount() {
        this.fetchProductInfos();
    }

    render() {
        let contents = this.state.loading
            ? <div className='loader' key="loader"></div>
            : this.renderData();

        return <div className="container">
            <div>
                {contents}
            </div>
        </div>;
    }

    renderData() {
        const { currentUser } = this.state;
        return (
            <div>
                <div class="newstitle">
                    <div class="well">
                        <h2>User: {currentUser.name}</h2>
                    </div>
                </div>
                <div className="well">
                    <h4>Licenses:</h4>
                    <p />
                    {
                        (this.state.productInfos.length > 0) ?
                            (
                                this.state.productInfos.map(n =>
                                    <div key={n.OrderId}>
                                        <div className="simpletable"><b>{n.name}</b>
                                            <br />Product Page (downloads and manual): <Link to={`/products/${n.name}`.toLowerCase()}>{n.name}</Link>
                                            <br/>
                                            {
                                            (n.useRsa2048 && n.serialNew != "") ? 
                                                (
                                                    <button className="btn btn-secondary" onClick=
                                                    {
                                                        (e) => saveAs(new Blob([n.serialNew], { type: "text/plain;charset=utf-8" }), n.name + "_SerialKey.txt") 
                                                    }>Download key for older releases</button>
                                                ) 
                                                :
                                                (<div/>)
                                            }
                                            {
                                                (n.serial.length > 2) ? 
                                                (
                                                    <div>Serial for releases before 2019: {n.serial}<br/></div>
                                                )
                                                :
                                                (
                                                    <div/>
                                                )
                                            }
                                            <br/>
                                            <button className="btn btn-secondary" onClick={
                                                        (e) => saveAs(new Blob([n.useRsa2048 ? n.serial2048 : n.serialNew], { type: "text/plain;charset=utf-8" }), n.name + "_SerialKey.txt") 
                                                    }>Download Actual Key</button>&nbsp;
                                            <button className="btn btn-secondary" onClick={(e) => this.transferLicenseEvent(e, n.orderId)}>Transfer License...</button>
                                        </div>
                                        <div>&nbsp;</div>

                                    </div>
                                ))
                            :
                            <div>No licenses found</div>
                    }
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <hr className='hr' />
                    <h5>Change Password</h5>
                    <ChangePasswordForm />
                    <div />
                </div>
            </div>
        );
    }

    downloadSerialEvent(event, orderId, productName) {
        return fetch(Config.apiUrl + '/userinfo/serial/' + orderId,
            {
                method: 'GET',
                type: 'text/plain;charset=utf-8',
                headers: AuthHeader()
            })
            .then(HandleResponse)
            .then(serialBlob => {
                var blob = new Blob([serialBlob.toString()], { type: "text/plain;charset=utf-8" });
                saveAs(blob, productName + "_SerialKey.txt")
            });
    }

    transferLicenseEvent(event, orderId) {
        this.props.navigate('/transferlicense/' + orderId, { state: this.props.location.state });
    }
}

export default (props) => (<UserAreaView {...props} params={useParams()} navigate={useNavigate()} location={useLocation()} />);
